<template>
  <div v-if="isLoaded">
    <!-- 头图 -->
    <img class="top-image" v-if="data.mainPicture !== ''" :src="data.mainPicture" :onerror="defaultImages[category]" />

    <!-- 内容块 -->
    <div class="detail-body">
      <!-- 标题 -->
      <div class="detail-title">{{data.title}}</div>

      <!-- 副标题 -->
      <div class="detail-subtitle flex-center-center">
        <div class="flex-center-center">
          <span class="iconfont icon-category"></span>
          <span>{{data.categoryLabel}}</span>
        </div>
        <div class="flex-center-center">
          <span class="iconfont icon-time"></span>
          <span>{{data.createdAt.substring(0, 10)}}</span>
        </div>
      </div>

      <!-- 内容 -->
      <div class="detail-content" v-viewer v-html="data.content"></div>

      <!-- 阅读数、喜欢数 -->
      <div class="icon-bar flex-row-between-center">
        <span class="icon-item">
          <span class="iconfont icon-read"></span>
          <span>{{data.readCount}}</span>
        </span>
        <span class="icon-item" @click="handleFavour">
          <span v-if="data.isFavour" class="iconfont icon-favour"></span>
          <span v-else class="iconfont icon-no-favour"></span>
          <span>{{data.favourCount}}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import loading from '@/mixins/loading'

export default {
  name: 'DataDetail',

  props: {
    category: {
      type: String,
      required: true,
      default: ''
    },
    id: {
      type: String,
      required: true,
      default: ''
    }
  },

  data () {
    return {
      defaultImages: {
        AssociationNews: require('@/assets/images/default_AssociationNews.png'),
        Creativity: require('@/assets/images/default_Creativity.png'),
        Policy: require('@/assets/images/default_Policy.png'),
        IndustryNews: require('@/assets/images/default_IndustryNews.png'),
        MemberNews: require('@/assets/images/default_MemberNews.png')
      },
      data: {}
    }
  },

  mixins: [loading],

  beforeRouteLeave (to, from, next) {
    to.meta.keepAlive = (to.path === '/member-news' || to.path === '/industry-list' || to.path === '/policy-list' || to.path === '/association-list' || to.path === '/creativity-list')
    next()
  },

  created () {
    this.showLoading()
    this.getInfoByID(this.id)
  },

  methods: {
    // 获取咨询
    getInfoByID (id) {
      this.api.getInfo({ id }).then(res => {
        const data = res.data
        const categoryMapping = {
          AssociationNews: '协会动态',
          Creativity: '创意推荐',
          Policy: '政策信息',
          IndustryNews: '产业动态',
          MemberNews: '会员动态'
        }
        data.categoryLabel = categoryMapping[data.category]
        this.data = data
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getInfoByID() })
      }).finally(() => {
        this.hideLoading()
      })
    },
    // 喜欢
    handleFavour () {
      if (this.data.isFavour) {
        this.api.delFavour({ id: this.id }).then(() => {
          this.data.favourCount -= 1
          this.data.isFavour = false
        }).catch(err => {
          if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.handleFavour() })
        })
      } else {
        this.api.addFavour({ id: this.id }).then(() => {
          this.data.favourCount += 1
          this.data.isFavour = true
        }).catch(err => {
          if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.handleFavour() })
        })
      }
    }
  }
}
</script>

<style scoped>
.icon-time, .icon-category {
  margin: 0 3px 0 10px;
}
.detail-content >>> img {
  display: block;
  max-width: 100%;
}

.icon-bar {
  border-top: 1px solid #eeeeee;
  padding-top: 10px;
  width: 100%;
}
.icon-item {
  display: flex;
  align-items: center;
  opacity: 0.5;
}
.icon-item > span:first-child {
  font-size: 15px;
}
.icon-item > span:last-child {
  font-size: 13px;
  margin-left: 5px;
}
</style>
