import { Indicator } from 'mint-ui'

export default {
  data () {
    return {
      isLoaded: false
    }
  },

  methods: {
    // 显示加载
    showLoading () {
      this.false = true
      Indicator.open()
    },

    // 隐藏加载
    hideLoading () {
      Indicator.close()
      this.isLoaded = true
    }
  }
}
